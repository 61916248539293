.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
  font-family: 'VT323', monospace;
}

.App-link {
  color: #61dafb;
}
.carousel .slide .legend {
   font-size: 1.75rem;
   opacity: 0.45;
}
.progress {
    font-size: 1.75rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* vietnamese */
@font-face {
      font-family: 'VT323';
        font-style: normal;
	  font-weight: 400;
	    font-display: swap;
	      src: url(https://fonts.gstatic.com/s/vt323/v12/pxiKyp0ihIEF2isQFJXUdVNFKPY.woff2) format('woff2');
	        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
		}
/* latin-ext */
@font-face {
      font-family: 'VT323';
        font-style: normal;
	  font-weight: 400;
	    font-display: swap;
	      src: url(https://fonts.gstatic.com/s/vt323/v12/pxiKyp0ihIEF2isRFJXUdVNFKPY.woff2) format('woff2');
	        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
		}
/* latin */
@font-face {
      font-family: 'VT323';
        font-style: normal;
	  font-weight: 400;
	    font-display: swap;
	      src: url(https://fonts.gstatic.com/s/vt323/v12/pxiKyp0ihIEF2isfFJXUdVNF.woff2) format('woff2');
	        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
		}
